body {
  background: #008082;
  font-family: sans-serif, serif, monospace;
  font-size: 13px;
  overflow: hidden;
}

.wrapper, .content, footer {
  margin: auto;
  margin-top: 20px;
  background: #bfb8bf;
  border: 2px solid;
  border-color: #fff8ff #000000 #000000 #fff8ff;
  padding: 2px 0;
}

.wrapper, .content {
  width: 800px;
}

.default-title {
  background: #808080;
  
  &.active-main-title {
    background: #00007f;
  }
}

.default-title, .post-title {
  padding: 2px 4px 3px 4px;
  position: relative;

  h1 {
    color: #bfb8bf;
    font-size: 14px;
    font-weight: bold;
  }
  
  img {
    float: left;
    width: 14px;
    height: 14px;
    margin: 0 3px 0 0;
  }
}

.post-title {
  background: #00007f;

  h1 {
    display: inline-block;
  }

  a {
    color: #000000;
  }

  .btn {
    background: #cccccc;
    width: 13px;
    height: 11px;
    float: right;
    border: 2px solid;
    border-color: #fff8ff #000000 #000000 #fff8ff;

    span {
      font-size: 11px;
      position: relative;
      left: 2px;
      top: -3px;
    }

    .fa-window-minimize, .fa-window-maximize {
      color: grey;
      text-shadow: 1px 1px 1px white;
      font-size: 10px;
      left: 1px;
    }

  }

  .btn-maximize {
    margin: 0 3px 0 0;
  }

  .btn-close:active {
    width: 12px;
    height: 10px;
  }
}

ul.topbar {
  list-style: none;
  padding: 0 10px;
  margin: 3px 0 3px 0;

  li {
    display: inline-block;
    margin: 0 5px 0 0;
  }
  a {
    text-decoration: none;
    color: #000000;
  }
}

.post-list, .folder-list, .post-content {
  background: #fff8ff;
  border-width: 2px;
  border-style: ridge groove groove ridge;
  border-color: #7f787f #fff8ff #fff8ff #7f787f;
}

.post-list {
  width: 70.2%;
  margin: 0 auto;
  min-height: 150px;
  max-height: 150px;
  overflow-y: scroll;
  display: inline-block;

  ul {
    list-style: none;

    li {
      display: inline-block;
      margin: 5px;
      width: 16ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;

      img {
        display: block;
        margin: 0 auto;
        margin-bottom: 8px;
      }

      a {
        text-decoration: none;
        color: #000000;
      }
    }
  }
}

.folder-list {
  width: 28%;
  margin: 0 auto;
  min-height: 150px;
  max-height: 150px;
  overflow-y: scroll;
  display: inline-block;

  img {
    height: 16px;
    width: 16px;
    margin: -2px 5px;
  }

  a {
    text-decoration: none;
    color: #000000;
    background: #fff8ff;
    display:block;
  }

  ul li {
    max-width: 27ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding:0 0 0 2px;

    ul {
      background:transparent url(/assets/img/pixel-alt.png) repeat-y 10px 0;

      li {
        background:transparent url(/assets/img/pixel-alt.png) repeat-x 0 8px;
        margin:4px 0 4px 12px;
        padding:0 0 0 10px;
      }
    }
  }
}

.post-total {
  .left, .right {
    border: 1px solid;
    border-color: #888888 #dadada #dadada #888888;
    display: inline-block;
    padding: 1px 3px;
  }
  .left {
    width: 33%;
    margin-left: 2px;
  }
  .right {
    width: 64%;
    margin-right: 2px;
  }
}

.post-content {
  position: relative;
  width: 98.5%;
  max-height: 400px;
  padding: 2px;
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: scroll;

  p {
    line-height: 150%;
    margin-bottom: 15px;
  }

  a {
    color: #0000ff;
    margin-bottom: 15px;
  }

  h1 {
    font-size: 20px;
    font-weight: 700;
    margin: 20px 0 10px 0;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    margin: 25px 0 5px 0;
  }

  em {
    font-style: italic;
  }

  blockquote {
    padding: 0 0 0 15px;
    margin: 15px 5px;
    border-left: 8px solid #000000;
  }

  strong {
    font-weight: 700;
  }

  hr {
    border-top: 3px solid #333333;
    border-left: 0;
  }

  ul {
    list-style: square;
    margin: 0 0 15px 30px;

    li {
      padding: 5px;

      ul {
        margin: 10px 0 0 15px;

        li {
          padding: 5px;
        }
      }
    }
  }

  ol {
    list-style: decimal-leading-zero;
    margin: 0 0 15px 30px;

    li {
      padding: 5px;

      ul {
        margin: 10px 0 0 15px;

        li {
          padding: 5px;
        }
      }
    }
  }

  table, th, td {
    border: 1px solid #222222;
    padding: 2px;
  }

  pre {
    margin-bottom: 15px;
  }

  iframe {
    width: 320px;
    height: 180px;
  }
}

// Customs additions

.inactive-folder {
  a {	color: grey; }
}

.post-link {
  img {
    height: 32px;
  }
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  
  .start-btn {
    border-top: 1px solid white;
    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
    border-left: 1px solid white;
    height: 18px;
    margin-left: 2px;
    padding: 1px 3px;
    box-shadow: 1px 1px 1px black;
    display: block;
    float: left;

    img {
      height: 20px;
      float: left;
    }

    span {
      display: block;
      margin: 2px 0px 0px 4px;
      float: right;
      color: black;
      font-weight: bold;
    }
  }

  .system-tray {
    border: 1px solid;
    border-color: #888888 #dadada #dadada #888888;
    padding: 2px 10px 2px 5px;
    margin-right: 5px;
    float: right;

    img {
      float: left;
      height: 18px
    }

    span.clock {
      float: right;
      margin: 2px 0px 0px 4px;
    }
  }
}

// Weather reports
ul.weather-locations {
  list-style: none;
  margin: 10px;

  li {
    display: inline;

    .active { font-weight: bold; }

    :before {
      content: '';
      display: inline-block;
      height: 2em;
      width: 2em;
      background-image: url('/assets/img/globe.ico');
      background-size: contain;
    }
  }
}

// Video posts
.content.videos-content {
  width: 342px;
  height: 245px;

  .post-content {
    padding: 0px;
  }
}

// Winamp
#winamp {
  height: 400px;
}

// Paint
#paint-iframe {
  width: 100%;
  height: 500px;
}

// Media queries
@media only screen and (max-width: 900px) {
  .content {
    width: 90%;
  }
}
@media only screen and (max-width: 700px) {
  .post-content {
    width: 98%;
  }
}
@media only screen and (max-width: 400px) {
  .post-content {
    width: 97%;
  }
}

@media only screen and (max-width: 810px) {
  .wrapper {
    width: 90%;
  }
  .post-total {
    .left {
      width: 37%;
    }
    .right {
      width: 58%;
    }
  }
}
@media only screen and (max-width: 750px) {
  body {
    overflow: scroll;
    padding-bottom: 40px;
  }
  .folder-list {
    width: 48%;
  }
  .post-list {
    width: 48%;
  }
  .post-total {
    .left {
      width: 36%;
    }
  }
  .post-content {
    max-height: none;
  }
}
@media only screen and (max-width: 610px) {
  .post-total {
    .left {
      width: 35.7%;
    }
  }
}
@media only screen and (max-width: 520px) {
  .post-list {
    width: 47.5%;
  }

  .post-total {
    .left {
      width: 34.5%;
    }
  }
}
@media only screen and (max-width: 410px) {
  .post-total {
    .left {
      width: 34%;
    }
  }
}
@media only screen and (max-width: 375px) {
  .post-list {
    width: 46.5%;
  }

  .post-total {
    .left {
      width: 33%;
    }
  }
}
@media only screen and (max-width: 320px) {
  .folder-list {
    width: 98%;
  }
  .post-list {
    width: 98%;
    float: left;
  }
}

::-webkit-scrollbar {
  width: 16px;
} /* this targets the default scrollbar (compulsory) */
::-webkit-scrollbar-track {
  background-image: url("/assets/img/track.png");
  background-repeat: repeat-x repeat-y;
} /* the new scrollbar will have a flat appearance with the set background color */
 
::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-width: 2px;
  border-style: groove ridge ridge groove;
  border-color: #fff8ff #000000 #000000 #fff8ff;
} /* this will style the thumb, ignoring the track */
 
::-webkit-scrollbar-button {
  background-color: #cccccc;
  border-width: 2px;
  border-style: groove ridge ridge groove;
  border-color: #fff8ff #000000 #000000 #fff8ff;
  background-position: center center;
  background-repeat: no-repeat;

  &:vertical:decrement {
    background-image: url("/assets/img/up.png");
  }

  &:vertical:increment {
    background-image: url("/assets/img/down.png");
  }

  &:horizontal:decrement {
    background-image: url("/assets/img/left.png");
  }

  &:horizontal:increment {
    background-image: url("/assets/img/right.png");
  }

  &:active {
    background-position: 1px 2px;
  }
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */
 
::-webkit-scrollbar-corner {
  background-color: #cacaca;
} /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */
